
.home-img-space {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	background-size: auto 120%;
	background-position: 80% 100%;
}

.top a{
/*	font-size: 24px;*/
	font-size: 2.01vh;
}

a {
	font-family: "helvetica";
}

a.text {
/*	font-size: 30px;*/
	font-size: 2.5125vh;
	color: #333333;
}

div.home {
/*	max-height: 100%;*/
}

div.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

div.top {
	position: relative;

	margin-top: 12vh;

	height: auto;
	left: 50%;
	transform: translate(-50%, -50%);

	/*border: solid 1px black;*/
}

h1.title {
/*	font-size: 80px;*/
	font-size: 6.7vh;
	color: black;
/*	color: #FFFFFF;*/
	white-space: nowrap;
}

h1 {
	font-family: "Benne";
/*	font-size: 60px;*/
	font-size: 5.025vh;
	letter-spacing: .6rem;
	margin-bottom: 0px;
}

button.body-button {
/*	width: 200px;*/
	width: 16.75vh;
/*	height: 50px;*/
	height: 4.18vh;
/*	line-height: 50px;*/
	line-height: 4.18vh;
	background-color: darkgoldenrod;
	color: #FFFFFF;
	border-color: white;
	border-style: solid;
	border-width: 1px;
	margin-top: 20px;
}

button.body-button a {
/*	font-size: 30px;*/
	font-size: 2.5125vh;
}

.home-description {
	position: absolute;
	top: 100%;/*
	border-color: black;
	border-style: solid;*/
	height: 96%;
	width: 100%;
}

.page-objetivo {
	border: solid white 1px;
	left: 0;
	width: 100%;
  	background-color: #bcb395;

	margin-top: 0px;
	margin-bottom: 10px;
}

.register {
	position: absolute;

	left: 20%;
	right: 20%;
	width: auto;

	padding: 20px;
	
	display: grid;
	grid-column-gap: 2%;
	grid-template-columns: 49% 49%;
}

.register-seleccion-image {
	background-size: 100% auto;	
}

.register-instruction {
	grid-column: 2 / 2;
  	background-color: #FBFBFB;

}

.register-new-personaje {
	grid-column: 1 / 1;	
  	background-color: #FBFBFB;
	margin: 0px;
}

.register-form-grid {
	grid-column: 2 / 2;
	display: grid;
	width: 100%;
	flex-direction: column;
	text-align: left;

	position: relative;
	left: 10%;
	width: 80%;
}

.register-form-grid input{
	margin-bottom: 10px;
}

.register-form-grid a{
	font-size: 18px;
	margin-bottom: 1px;
}

@media(max-width: 900px){
	.home-img-space {
		background-size: auto 100vh;
		background-position: 65% 20%;
	}

	h1.title {
		font-size: 20px;
	}

	h1 {
		font-size: 6vw;
	}

	.top a {
		font-size: 4vw;
	}

	a.text{
		font-size: 18px;
	}

	button.body-button {
		/*width: 160px;*/
		width: 13.4vh;
		/*height: 40px;*/
		height: 3.34vh;
		line-height: 25px;
		background-color: darkgoldenrod;
		color: #FFFFFF;
		border-color: white;
		border-style: solid;
		border-width: 1px;
		margin-top: 20px;
	}

	button.body-button a {
		font-size: 18px;
	}
}

/* ------------------------------------------------------------------------ */

/*div.recuadros_container {
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	top: 200px;
	left: 5%;
	width: 90%;
	height: auto;
}
*/
div.recuadros_container {                                                                   
	position: absolute;                                                                     
                                                                                            
	left: 10vw;                                                                            
	right: 9.6vw;                                                                           
	height: auto;                                                                           
                                                                                            
	/*border: solid 1px black;*/                                                                
                                                                                            
	display: grid;                                                                          
	grid-template-columns: repeat(auto-fill, 20vw); /* 28 * 28 * 28 = > 20 8 20 8 20 8 */ 
	grid-auto-rows: 32vw;                                                                   
	grid-row-gap: 1vh;                                                                      
	grid-column-gap: 9.5vw; /* (ancho de columna * 3 - 2 * left)/ 4*/                         
}                                                                                           

/*div.recuadros_container {                                                                   
	position: absolute;                                                                     
                                                                                            
	left: 9.9vw;                                                                            
	right: 0.1vw;                                                                           
	height: auto;                                                                           
                                                                                            
	border: solid 1px black;                                                                
                                                                                            
	display: grid;                                                                          
	grid-template-columns: repeat(auto-fill, 29.5vw);  
	grid-auto-rows: 32vw;                                                                   
	grid-row-gap: 1vh;                                                                      
	grid-column-gap: 0vw;           
}                                                                                           
*/
@media (max-width: 900px){
	div.recuadros_container {
		position: absolute;
		left: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}

/* -------------------------- Personaje Page ---------------------------------- */

.personaje-grid {
	display: grid;
	grid-template-columns: 5;
  	grid-gap: 10px;

	position: absolute;
	top: 100px;
	left: 10%;
	width: 80%;
}

.personaje-h {
	grid-column: 2;
	font-size: 30px;
}

.personaje-p {
	grid-column: 2;
	font-size: 24px;
}

.personaje-image {
	grid-column: 2 / 3;
	height: 60vh;
	max-width: 50vw;

	/*border-color: green;
	border-style: solid;
	border-width: 10px;*/
}

.personaje-image img{
	height: 100%;
}

.personaje-slider {
	grid-column: 2 / 3;
	max-width: 40vw;

	height: 60vh;
}

.personaje-form {
	grid-column: 3 / 5;
}

.form-grid {
	display: grid;
	width: 100%;
	flex-direction: column;
	text-align: left;
}

.form-grid input{
	margin-bottom: 10px;
}

.form-grid a{
	font-size: 18px;
	margin-bottom: 1px;
}

.indication {
	display: none;
}

.form-password:focus + .indication {
	display: block;
}

.invitado-submit {
	position: relative;
	left: 25%;
	width: 50%;
	height: 30px;
	color: white;
	background-color: darkgoldenrod;
}

.invitado-submit:hover {
  filter: brightness(115%);
}

@media(max-width: 900px){
	.personaje-grid {
		display: flex;
		flex-direction: column;

		top: 100px;
		left: 10%;
		width: 80%;
	}

	.personaje-h {
		font-size: 20px;
	}

	.personaje-p {
		font-size: 16px;
	}

	.personaje-image {
		max-width: none;
		max-height: none;
		height: auto;
		width: 100%;
	}

	.personaje-slider{
		max-width: none;
		max-height: none;
		height: auto;
		width: 100%;
	}

	.personaje-image img{
		max-width: none;
		max-height: none;
		height: auto;
		width: 100%;
	}

	.personaje-form {

	}
}

/* ------------------------------------ Search ------------------------------- */
.search {
	position: absolute;
	top: 20%;
	left: 40%;
	height: auto;
	width: 20%;

	display: flex;
	flex-direction: column;
}

.search-spacer {
	height: 40%;
	width: 60%;
}

.search-bar{
}

.search-list{
	width: 100%;
}

@media(max-width: 900px){
	.search {
		position: absolute;
		top: 20%;
		left: 10%;
		height: auto;
		width: 80%;

		display: flex;
		flex-direction: column;
	}

}

/* ------------------------------------------- FORM ---------------------------------------------- */
.recolocar-form {
	position: absolute;
	top: 40%;
	left: 50%;

	transform: translate(-50%, -50%)
}

.login-form {
	width: 400px;

	padding: 20px;
	background-color: #FBFBFB;

	display: flex;
	flex-direction: column;
	text-align: left;
}

.login-form a {
	margin: 10px 0px 10px 0px;
}

.login-form button{
	position: relative;
	left: 50%;
	transform: translate(-50%, 0%)
}

@media(max-width: 600px){
	.recolocar-form {
	}

	.login-form{
		position: relative;
		width: 80vw;
	}

	.login-form button{ 
		left: 50%;
		transform: translate(-50%, 0%);
	}
}
