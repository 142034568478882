div.NavBar {
    position: fixed;
    top: 0;
    left: 0;
/*    height: 50px;*/
    height: 4.18vh;
    width: 100%;
    background-color: #bcb395;
}

a {
  font-family: "helvetica";
}

ul.NavBar {
  list-style-type: none;
/*  margin: 16px;*/
  margin: 1.34vh;
  padding: 0;
/*  height: 100%;*/
}

ul.NavBar li {
    display: inline;
    float: right;
/*    margin-right: 12px;*/
    margin-right: 1vh;
    height: 100%;
}

ul.NavBar li a{
    display: inline;
/*    margin-right: 20px;*/
    margin-right: 1.67vh;
    cursor: pointer;
    height: 100%;
    text-decoration: none;
    color: #333333;
/*  font-size: 20px;*/
    font-size: 1.675vh;
}

div.navbar-element a{
}

div.navbar-element:hover a{
    color: goldenrod;
}

/*
ul.NavBar li div:hover a{
  color: darkgoldenrod;
}*/

.subnav {
  float: left;
  overflow: inherit;
}

.subnav-element {
/*  padding: 5px;*/
  padding: 0.42vh;
  text-align: left;
/*  border-color: black;
  border-style: solid;
  position: relative;*/
}

div.subnav-element a{
  color: black;
}

div.subnav-element:hover a{
    color: goldenrod;
}

.subnav-content {
  display: none;
  position: relative;

  flex-direction: column;
  top: 100%;
  left: 0;
  background-color: whitesmoke;
  z-index: 1;
}

.subnav:hover .subnav-content {
  display: flex;
}

/* ------------------------------------------ List ------------------------------------------- */
ul.search-list{
    display: flex;
    flex-direction: column;
}

ul.search-list li{
}

ul.search-list li a{
    text-decoration: none;
    text-align: left;
    float: left;
}

li.search-list-universos a{
    color: blue;
}

li.search-list-personajes a{
    color: red;
}


/* -------------------------------------------- Personaje --------------------------------------------------------------  */

div.taken-personaje {
	height: 25vw;
	width: 20vw;
    /*border: solid 1px black;*/
    margin-bottom: 20px;
    position: relative;
}

div.taken-personaje:hover {
	animation: shake 0.5s;

}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

div.taken-personaje:hover .taken-shade a{
	-webkit-text-stroke: 1px black;

	font-family: "helvetica";
	font-size: 2vw;
	color: red;
}

div.personaje:hover .shade{
    background: rgba(0,0,0,0.2);
}

div.taken-shade{
    width: 100%;
    height: 90%;
    background: rgba(0,0,0,0.7);
    position: absolute;
    top: 0;
    left: 0;
}

div.taken-shade a{
    color: white;
    font-size: 1.5vw;
    margin: 0;
    transform: translate(-50%,-50%);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
}

.personaje-img {
    padding: 10% 10% 0% 10%;
    width: 80%;
    height: 80%;
}

img.personaje {
    height: 100%;
    width: 100%;
}

@media (max-width: 900px){
    div.personaje {
        left: 10%;
        width: 80%;
    }
    
    div.personaje:hover {/*
        margin-top: -1%;
        margin-bottom: -1%;
        width: 82%;*/
        margin-bottom: 20px;
        width: 80%;
        height: 600px;
    }

    div.taken-personaje {
        left: 10vw;
        width: 80vw;
		height: 100vw;
        /*border: solid 1px black;*/
        margin-bottom: 20px;
        position: relative;
    }

	div.taken-shade a{
		font-size: 6vw;
	}

	div.taken-personaje:hover .taken-shade a{
		font-size: 8vw;
	}
}
/* ------------------------------------- Recuadros general ---------------------------------- */
div.recuadro {
    /*height: 500px;
    width: 400px;
*/
	height: 25vw;
	width: 20vw;

	/*border: solid 1px black;*/
	

    position: relative;
    background-color: #FBFBFB;
}

div.recuadro:hover {
    height: 26vw;
    width: 21vw;
    margin: -1vw;
}

div.shade{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0);
    position: absolute;
    top: 0;
    left: 0;
}

div.shade:hover{
    background: rgba(0,0,0,0.2);
}

div.recuadro:hover .shade{
    background: rgba(0,0,0,0.2);
}

div.recuadro-img-space {
    width: 100%;
    height: 90%;
    position: relative;
}

.recuadro-img {
    padding: 10% 10% 0% 10%;
    width: 80%;
    height: 80%;
/*    height: auto;*/

    display: grid;
    grid-template-columns: 2;
    grid-template-rows: 2;/*
    border-color: black;
    border-style: solid;*/

    transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -webkit-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
}

div.taken-recuadro {
    /*height: 500px;*/
	height: 25vw;
    /*width: 400px;*/
	width: 20vw;
    border-color: black;
    border-style: solid;
    margin-bottom: 20px;
    position: relative;
}
/*
.label {
    width: 100%;
    height: 10%;
    position: relative;
}

.label a{
    color: black;
    font-size: 24px;
    margin: 0;
    transform: translate(-50%,-50%);
    position: absolute;;
    top: 70%;
}
*/

.label a{
    color: black;
    font-size: 1.5vw;
    margin: 0;
    transform: translate(-50%,-50%);
    text-align: center;
    position: absolute;
    top: 90%;
    left: 50%;

	width: 100%;

	/*border-color: black;
	border-style: solid ;
	border-width: 1px ;*/
}

.show-animation{
    opacity: 0;
    transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -webkit-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
}

.sub-imgs {
    position: absolute;
    top: 0;
    padding: 10% 10% 0% 10%;
    width: 80%;
    height: 80%;

    display: grid;
    grid-template-columns: 2;
    grid-template-rows: 2;
}

img.sub-img1 {
    height: 100%;
    width: 100%;
    grid-column: 1;
    grid-row: 1;
}

img.sub-img2 {
    height: 100%;
    width: 100%;
    grid-column: 2;
    grid-row: 1;
}

img.sub-img3 {
    height: 100%;
    width: 100%;
    grid-column: 1;
    grid-row: 2;
}

img.sub-img4 {
    height: 100%;
    width: 100%;
    grid-column: 2;
    grid-row: 2;
}


div.recuadro:hover img.recuadro-img{
/*    display: none;*/
    opacity: 0;
}

div.recuadro:hover .show-animation{
    opacity: 1;
}


@media (max-width: 900px){
    div.recuadro {
        left: 10vw;
        width: 80vw;
		height: 100vw;
    }

    div.recuadro:hover {
/*        margin: -1%;*/
        width: 82vw;
		height: 102vw;
        margin: -1vw -1vw 3vw -1vw;
/*        height: auto;*/
    }

	.label a{
		font-size: 6vw;
	}
}
